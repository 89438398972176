import { BACKEND_URL } from './env';

export function buyBattlepass(amount: 2500 | 5000 | 10000): Promise<string> {
  return fetch(`${BACKEND_URL}/api/battlepass-service/battlepass/buy`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      amount,
    }),
  }).then((data) => {
    if (data.ok) {
      return data.text();
    }

    throw new Error();
  });
}
