import { makeStyles } from 'theme';

export const useStyle = makeStyles()(() => ({
  modal: {
    width: 620,
    display: 'flex',
    flexDirection: 'column',
    padding: '16px 32px',
  },
  form: {
    backgroundColor: '#2B3353',
    padding: '16px 20px',
    borderRadius: 6,
  },
  title: {
    textAlign: 'center',
    marginTop: '40px',
  },
  textWarning: {
    textAlign: 'center',
    margin: '20px 0',
  },
  text: {
    display: 'inline-block',
  },
  cardInfo: {
    display: 'flex',
    gap: 8,
  },
  cardDate: {
    display: 'flex',
    width: '50%',
    gap: 4,
  },
  currencyItem: {
    display: 'flex',
    flexDirection: 'column',
    gap: 4,
  },
  addressInput: {
    width: '100%',
  },
  amountInput: {
    width: 172,
  },
  cancelBtn: {
    marginRight: 20,
    marginLeft: 'auto',
  },
  footer: {
    display: 'flex',
    alignItems: 'center',
  },
  amountContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    gap: 20,
  },

  battlepassBlock: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '6px',
  },
  battlepassBlockText: {
    marginTop: '22px',
  },
  battlepassButton: {
    marginTop: '25px',
  },
}));
